*,
*::before,
*::after {
  box-sizing: border-box;
}
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}
html{
  min-height: 100%;
    display: flex;
    flex-direction: column;
}
body {
  margin: 0;
  background-color: #431c51;
  /* background: linear-gradient(#50006c, #79568b);
  background: rgb(67,28,81);
background: linear-gradient(180deg, rgba(67,28,81,1) 0%, rgba(78,28,81,1) 70%, rgba(121,86,139,1) 100%);
  font-family: "Inter", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-family: "Poppins", system-ui;
  height: 100dvh;
}
.body-bg-img{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
  /* min-height: 100%; */
  display: flex;
  flex-direction: column;
  flex: 1;
  /* min-height: 100vh;   */
  height: 100dvh;     
}

p {
  margin: 0;
  padding: 0;
}
.app-p-wrap{
  height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;
    height: 100dvh;
}
.voice-chat-txt{
  max-width: 550px;
    text-align: center;
    margin: 30px auto;
    font-size: 26px;
    font-weight: 500;
    padding: 0px 20px;
}

.play-layer-wrapper {
  position: relative;
}

.play-layer {
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100vh;
}

.sound-wave {
  min-width: 400px;
  display: flex;
  flex-direction: column;
}
.sound-wave svg {
  display: block;
  max-width: 63rem;
  height: auto;
}


.progress-bar {
  height: 10px;
  width: 35%;
  border-radius: 30px;
  border: 2px solid #fff;
  background-color: #fff;
  margin: -2rem auto 6rem;
}

.progress-color {
  background: #1389e9;
  height: 100%;
  width: 0;
  border-radius: 30px;
  transition: width 4s linear;
}

.d-none {
  display: none;
}

@keyframes scale1 {
  0% {
    transform: scaleY(0.6);
  }
  40% {
    transform: scaleY(1.2);
  }
  100% {
    transform: scaleY(0.6);
  }
}

.bar-scale1 {
  animation: scale1 0.6s infinite;
  transform-origin: center;
}

@keyframes scale2 {
  0% {
    transform: scaleY(0.5);
  }
  30% {
    transform: scaleY(1.6);
  }
  100% {
    transform: scaleY(0.5);
  }
}

.bar-scale2 {
  animation: scale2 0.6s infinite;
  transform-origin: center;
}

@keyframes scale3 {
  0% {
    transform: scaleY(1.5);
  }
  70% {
    transform: scaleY(0.5);
  }
  100% {
    transform: scaleY(1.5);
  }
}

.bar-scale3 {
  animation: scale3 0.6s infinite;
  transform-origin: center;
}

.stop-animation {
  animation-duration: 0s;
}

.audio-buttons {
  position: relative;
}

.play-button svg {
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.65);
  border-radius: 50px;
}

.play-button,
.pause-button {
  width: 46px;
  height: 46px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.bar-scale1 {
  stroke: #fff; /* Set stroke color as white or any solid color */
 
}

.bar-scale2 {
  stroke: #fff; /* Set stroke color as white or any solid color */
  
}

.bar-scale3 {
  stroke: #fff; /* Set stroke color as white or any solid color */
 
}
.voice-img{
  width: 100px;
  transition: ease all 0.5s;
  cursor: pointer;
}
.voice-img:hover {
  transform: scale(1.1);
}
.voice-p-wrap{
  display: flex ;
  align-items: end;
  justify-content: center;
  position: relative;
}
.voice-img-btn{
  display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    background: none;
    box-shadow: none;
    outline: none;
    border: none;
}
.voice-img-btn {
  -webkit-tap-highlight-color: transparent;
}
.loader-common span {
  width: 20px !important;
  height: 10px !important;
  margin: 0px 5px !important;
  border-radius: 0px !important;
}
.call-label{
  margin: 0px;
    color: #fff;
    margin-top: 10px;
    letter-spacing: 0.2px;
    box-shadow: rgba(128, 255, 204, 0.2) 0px 0px 8px 0.5px;
    min-width: 82px;
    opacity: 1;
    transform: none;
    padding: 10px 20px;
    border-radius: 24px;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.4);
}
.animated-circle{
  filter: brightness(0.9);
  width: 120px;
  cursor: pointer;
  height: 120px!important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;
  min-height: 120px;
}
.animatied-loader-p-wrap{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 170px;
}
.animated-circle:hover{
  filter: brightness(1);
}
.bounce-animation {
  animation: bouncesvg 3s ease-in-out infinite;
  width: 100%;
    height: 100%;
}
.opacity-animation{
  animation: fadeInOut 3s ease-in-out infinite;
  width: 100%;
    height: 100%;
}

@keyframes bouncesvg {
  0%, 100% {
      transform: translateY(5px);
  }
  50% {
      transform: translateY(-1px);
  }
}
@keyframes fadeInOut {
  0%, 100% {
      opacity: 0.3;
  }

  50% {
      opacity: 1;
  }
}
.opacity-animation-waves{
  animation: fadeInOutWaves 3s ease-in-out infinite;

}
@keyframes fadeInOutWaves {
  0%, 100% {
      opacity: 0.7;
  }

  50% {
      opacity: 1;
  }
}
.end-call-animation-btn-p {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
}
.end-call-animation-btn-p .animated-circle {
  width: 60px;
  height: 60px !important;
  min-height: 60px;
}
.end-call-animation-btn-p .circle-backdrop {
  
    background: radial-gradient(54.75% 54.75% at 50% 50%, rgb(195 131 131 / 22%) 70.24%, rgb(211 166 227) 100%), linear-gradient(135deg, rgb(124 14 14 / 54%) 0%, rgb(255 42 42 / 0%) 100%), radial-gradient(50% 50% at 50% 50%, rgb(251 0 0 / 22%) 0%, rgb(240 51 51) 90.5%);
  
}

.end-call-animation-btn-p .call-label {
  padding: 7px 0px;
  font-size: 12px;
}
.push-to-talk-ani-p.active .circle-backdrop{
  background: radial-gradient(54.75% 54.75% at 50% 50%, rgb(225 225 225 / 22%) 70.24%, rgb(188 0 255) 100%), linear-gradient(135deg, rgb(38 74 79 / 54%) 0%, rgba(93, 254, 202, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgb(1 1 1 / 22%) 0%, rgb(185 0 255) 90.5%);
} 
.push-to-talk-ani-p.inactive .circle-backdrop{
  background: radial-gradient(54.75% 54.75% at 50% 50%, rgb(195 131 131 / 22%) 70.24%, rgb(211 166 227) 100%), linear-gradient(135deg, rgb(124 14 14 / 54%) 0%, rgb(255 42 42 / 0%) 100%), radial-gradient(50% 50% at 50% 50%, rgb(251 0 0 / 22%) 0%, rgb(240 51 51) 90.5%);
} 
.boun-box {
  box-shadow: rgba(148, 255, 210, 0.2) 0px 20px 50px 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.boun-box svg {
  fill: #ecd3f5;
}
.boun-box svg {
  z-index: 3;
}
.boun-box svg path {
  z-index: 2;
  stroke: #ecd3f5;
}

.circle-backdrop{
      overflow: hidden;
      border-radius: 9999px;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background:radial-gradient(54.75% 54.75% at 50% 50%, rgba(0, 0, 0, 0.22) 70.24%, rgb(211 166 227) 100%), linear-gradient(135deg, rgba(22, 35, 37, 0.54) 0%, rgba(93, 254, 202, 0) 100%), radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.22) 0%, rgb(220 191 231) 90.5%);
      backdrop-filter: blur(23.5362px);
}
.logo-m-wrap {
  display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.logo-m-wrap img{
  height: 80px;
}
.body-m-wrap{
  height: 100%;
    display: flex;
    flex-direction: column;
}
.y-b-version {
  display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
}
.y-b-version span{
  padding: 0px 10px;
    background-color: #EEBA2B;
    color: #000;
    font-size: 14px;
    border-radius: 20px;
    font-weight: 500;
}
.voice-ai-text{
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
    font-size: 20px;
    margin-bottom: 20px;
}
.pwrd-by-s-b{
  color: #fff;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  flex: none;
  }
  .pwr-sec{
    margin-top: 20px;
  }
  .pwrd-by-s-b img{
    height: 50px;
  }
  .pwrd-by-s-b-i{
  font-size: 12px;
  }
  .pwr-sec{
    display: flex;
    align-items: center;
    /* margin-top: 30px; */
  }
  .f-b-version{
    font-size: 14px;
    margin-top: 40px;
  }
  .ask-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 10px;
    max-height: 70px;
  }
  .ask-wrapper-txt{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 15px;
    font-size: 14px;
  }
  .ask-wrapper .swiper{
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
  .ask-wrapper-sp{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .can-ask-text{
    font-size: 14px;
    font-weight: normal;
  }
  .animated-circle.main-page{
    width: 170px;
    height: 170px !important;
    min-height: 170px;
  }
  .flex-1{
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  

@media (max-width:600px) {
  .sound-wave{
    min-width: 320px;
  }
  .voice-chat-txt{
    font-size: 26px;
    padding: 0px 30px;
  }
  .voice-p-wrap{
    margin-top: 10px;
  }
  .d-margin-cen{
    margin-top: 0px!important;
  }
  /* .ask-wrapper{
    max-height: 65px;
  } */
  .d-margin-cen{
    margin: 0px 20px 0px 20px;
  }
  .app-p-wrap{
    min-height: 100vh; 
    min-height: 100dvh; 
    /* height: 100dvh;
    min-height: 100svh;
    height: 100svh; */
  }
  .voice-ai-text{
    margin-bottom: 10px;
  }
}


@media (max-width:385px) {
  .voice-chat-txt{
    font-size: 22px;
  }
  .sound-wave{
    min-width: 260px;
  }
}